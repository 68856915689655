<template>
  <div class="container">
    <edit-header
      entity="brand"
      :error.sync="error"
      :invalid.sync="invalid"
      :submitted.sync="submitted"
      :isNew="isNew"></edit-header>
    <form
      v-disable-all="!can(uiPermissions.BRANDS_UPDATE)"
      ref="form"
      class="validation"
      novalidate
      @submit.prevent="submit">
      <div class="row">

        <div class="col">
          <div class="card">
            <div class="card-header">
              <h3 class="card-title">Brand</h3>
            </div>
            <div class="card-body">
              <div class="form-group">
                <label data-test="label-name" class="form-label">Name <span class="text-danger">*</span></label>
                <input
                  v-model="item.name"
                  type="text"
                  data-test="txt-name"
                  class="form-control"
                  :class="{ 'is-invalid': invalid && response.error.name }"
                  required>
                <small v-if="invalid && response.error.name" class="error text-danger">
                  {{response.error.name.join(', ')}}
                </small>
              </div>
              <div class="form-group">
                <label data-test="label-description" class="form-label">Description</label>
                <textarea
                  v-model="item.description"
                  data-test="txt-description"
                  class="form-control">
                </textarea>
              </div>
            </div>
          </div>
        </div>

      </div>
      <div class="row">

        <div class="col">
          <edit-footer
            :dirty="dirty"
            entity="brand"
            :isNew="isNew"
            :submitting="submitting"
            :hide-delete="true"></edit-footer>
        </div>

      </div>
    </form>
  </div>
</template>

<script>
import EditFooter from '@/components/EditFooter';
import EditHeader from '@/components/EditHeader';
import edit from '@/mixins/edit';
import {brands} from '@/services';

export default {
  components: {
    EditFooter,
    EditHeader,
  },
  mixins: [
    edit,
  ],
  data() {
    return {
      item: {
        description: '',
        name: '',
      },
    };
  },
  computed: {
    route() {
      return `/brands/${this.item.id}`;
    },
  },
  methods: {
    fetchData(id) {
      return brands.getById(id);
    },
    async handleDelete() {
      if (window.confirm('Deleting brand cannot be undone. Are you sure that you want to delete this brand?')) {
        await brands.deleteById(this.item.id);
        this.$router.push('/brands');
      }
    },
    submitData(item) {
      return brands.saveOrUpdate(item);
    },
  },
};
</script>
